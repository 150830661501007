import React from 'react';
import { graphql } from 'gatsby';
import Path from '../../services/path';
import { preparePresenterStationData } from '../../services/station-helper';
import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';
import PresenterBio from '@bfbs/storybook/dist/components/organisms/presenter-bio/presenter-bio';

const Presenter = ({ data: { presenter } }) => {
  const breadcrumbs = [
    { name: 'Radio', path: Path.basePath },
    { name: 'Presenters', path: Path.presenterList },
    { name: presenter.title, path: null },
  ];

  /**
   * Generate the social links array.
   *
   * @returns {[]}
   */
  const prepareSocialLinks = () => {
    const socialLinks = [];
    // Email.
    if (presenter.field_values.contact_email) {
      socialLinks.push({
        icon: 'envelope',
        text: presenter.field_values.contact_email,
      });
    }
    // Facebook.
    if (presenter.field_values.contact_facebook) {
      socialLinks.push({
        icon: 'facebook',
        text: presenter.field_values.contact_facebook,
      });
    }
    // Instagram.
    if (presenter.field_values.contact_instagram) {
      socialLinks.push({
        icon: 'instagram',
        text: presenter.field_values.contact_instagram,
      });
    }
    // Twitter.
    if (presenter.field_values.contact_twitter) {
      socialLinks.push({
        icon: 'twitter',
        text: presenter.field_values.contact_twitter,
      });
    }

    return socialLinks;
  };

  const socialLinks = prepareSocialLinks();

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title={presenter.title} />

      <SlimlineBannerBlock background="background--tertiary-two" heading={presenter.title} />

      <PresenterBio
        stations={presenter.station ? preparePresenterStationData(presenter.station) : []}
        image={presenter.field_values.image}
        text={presenter.field_values.content}
        title={presenter.title}
        touchPoints={socialLinks}
        ctaUrl={Path.presenterList}
      />
    </Layout>
  );
};

export default Presenter;

export const presenterQuery = graphql`
  query PresenterQuery($id: String!) {
    presenter(bfbs_id: { eq: $id }) {
      title
      field_values {
        contact_email
        contact_facebook
        contact_instagram
        contact_twitter
        image
        content
      }
      station {
        title
        metadatastation {
          logo600x600
          logo320x240
          logo300x300
          logo128x128
          weight
          svg1by1
        }
      }
    }
  }
`;
